<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAttestatiQualifiche"
        @current-change="setCurrentPageAttestatiQualifiche"
      >
        <template v-slot:cell-nominativo_completo="{ row: data }">
          <router-link
            :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
          >
            <div class="tab-long">
              {{ data.cognome }}, {{ data.nome }} - {{ data.data_nascita }}
              <span v-if="!data.cognome"> - </span>
            </div>
          </router-link>
        </template>
        <template v-slot:cell-tipo="{ row: data }">
          <div class="tab-long">
            {{ data.tipo }}
          </div>
        </template>
        <template v-slot:cell-data_emissione="{ row: data }">
          <span v-if="data.data_ora_ins"> {{ data.data_ora_ins }}</span>
          <span v-if="!data.data_ora_ins"> - </span>
        </template>
        <!-- <template v-slot:cell-codice_snm="{ row: data }">
          <div :class="data.no_qualifiche ? 'text-danger' : ''">
            {{ data.codice_snm }}
            <span v-if="!data.codice_snm"> - </span>
          </div>
        </template> -->
        <!-- <template v-slot:cell-codice_dir="{ row: data }">
          <div :class="data.no_qualifiche ? 'text-danger' : ''">
            {{ data.codice_dir }}
            <span v-if="!data.codice_dir"> - </span>
          </div>
        </template> -->
        <!-- <template v-slot:cell-numero_albo="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <template
              v-if="
                idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
              "
            >
              {{ qualifica.numero_albo }}
              <span v-if="!qualifica.numero_albo"> - </span>
            </template>
            <template v-else>
              {{ qualifica.numero_albo }}
              <span v-if="!qualifica.numero_albo"> - </span>
            </template>
          </div>
        </template> -->
        <!-- <template v-slot:cell-tipo_albo="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <template
              v-if="
                idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
              "
            >
              {{ qualifica.albo }}
              <span v-if="!qualifica.albo"> - </span></template
            ><template v-else>
              {{ qualifica.albo }}
              <span v-if="!qualifica.albo"> - </span>
            </template>
          </div>
        </template> -->
        <template v-slot:cell-qualifica="{ row: data }">
          <div class="tab-long">
            {{ data.qualifica }}
            <span v-if="!data.qualifica"> - </span>
          </div>
        </template>
        <!-- <template v-slot:cell-data_acq="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <template
              v-if="
                idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
              "
            >
              <span v-if="qualifica.data_acq"
                ><div class="text-center">
                  {{ getFormatDate(qualifica.data_acq) }}
                </div></span
              >
              <span v-if="!qualifica.data_acq"> - </span>
            </template>
            <template v-else>
              <span v-if="qualifica.data_acq"
                ><div class="text-center">
                  {{ getFormatDate(qualifica.data_acq) }}
                </div></span
              >
              <span v-if="!qualifica.data_acq"> - </span>
            </template>
          </div>
        </template>
        <template v-slot:cell-data_decorrenza="{ row: data }">
          <div
            class="my-2"
            :class="data.no_qualifiche ? 'text-danger' : ''"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            <template
              v-if="
                idQualificaAlbo && qualifica.id_qualifica == idQualificaAlbo
              "
            >
              <span v-if="qualifica.data_dec"
                ><div class="text-center">
                  {{ getFormatDate(qualifica.data_dec) }}
                </div></span
              >
              <span v-if="!qualifica.data_dec"> - </span>
            </template>
            <template v-else>
              <span v-if="qualifica.data_dec"
                ><div class="text-center">
                  {{ getFormatDate(qualifica.data_dec) }}
                </div></span
              >
              <span v-if="!qualifica.data_dec"> - </span>
            </template>
          </div>
        </template>
        <template v-slot:cell-attestato_generato="{ row: data }">
          <div class="my-2">
            <span class="text-success" v-if="data.attestato_generato">SI</span>
            <span class="text-danger" v-else>NO</span>
          </div>
        </template> -->
        <template v-slot:cell-scaricato="{ row: data }">
          <div class="my-2">
            <span class="text-success" v-if="data.scaricato">SI</span>
            <span class="text-danger" v-else>NO</span>
          </div>
        </template>
        <template v-slot:cell-inviato="{ row: data }">
          <div class="my-2">
            <span class="text-success" v-if="data.inviato">SI</span>
            <span class="text-danger" v-else>NO</span>
          </div>
        </template>
        <template v-slot:cell-data_ora_dw="{ row: data }">
          <div class="my-2">
            <span v-if="data.data_ora_download">
              {{ data.data_ora_download }}</span
            >
            <span v-if="!data.data_ora_download"> - </span>
          </div>
        </template>

        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <!-- <button
              class="btn btn-sm dropdown p-1"
              @click="generaAttestatiPersona(data.qualifiche)"
            >
              <i class="bi bi-eye text-dark fs-5"></i>
            </button> -->
            <button
              class="btn btn-sm dropdown p-1"
              @click="
                cancellaQualifica(
                  data.id,
                  data.cognome,
                  data.nome,
                  data.data_nascita
                )
              "
            >
              <i class="bi bi-trash text-dark fs-5"></i>
            </button>
          </div>
        </template>
      </Datatable>
    </div>

    <SelectEditElemento
      :albo="selectedAttestatiQualifiche"
      :persona="selectedPersona"
      :isLoadingDetail="isLoadingDetail"
    />
    <DeleteQualifiche
      :albo="selectedAttestatiQualifiche"
      :persona="selectedPersona"
      @refreshAlboList="refreshAttestatiQualificheList"
      :isLoadingDetail="isLoadingDetail"
    />
    <AddUtenzaAlbo
      :persona="personaUtenza"
      @refreshAlboList="refreshAttestatiQualificheList"
    />
    <ListaCorsiAggiornamentoSnm
      :nominativo_persona="selectedPersona"
      :corsi_aggiornamento_snm_list="corsi_aggiornamento_snm_list"
      :loadingDetail="loadingDetail"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed, getCurrentInstance } from "vue";
import { viewCorsiAggiornamentoList } from "@/requests/alboInsegnateRequests";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";

import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { alertChoice, alertFailed, alertSuccess } from "@/composables/swAlert";

import ListaCorsiAggiornamentoSnm from "@/components/components-fit/SNM/albo-snm/ListaCorsiAggiornamentoSnm.vue";
import services from "@/axios/dbManag";
import { callFunctionAfterAlert } from "@/composables/swAlert";

export default {
  name: "TableAlbo",
  components: {
    Datatable,
    Loading,

    ListaCorsiAggiornamentoSnm,
  },
  emits: ["getAttestatiQualificheList", "resetFilters"],
  setup(props, { emit }) {
    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const store = useStore();

    const setFetchRowsAttestatiQualifiche = (e) => {
      store.commit("setFetchRowsAttestatiQualifiche", e);
      emit("getAttestatiQualificheList");
    };
    const setCurrentPageAttestatiQualifiche = (page) => {
      store.commit("setCurrentPageAttestatiQualifiche", page);
      emit("getAttestatiQualificheList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAttestatiQualifiche", columnName);
      store.commit("setSortOrderAttestatiQualifiche", order);
      emit("getAttestatiQualificheList");
    };

    const refreshAttestatiQualificheList = () => {
      emit("getAttestatiQualificheList");
    };

    const isLoadingDetail = ref(false);

    const selectedAttestatiQualifiche = ref({});
    const selectedPersona = ref("");

    const isLoading = ref(false);

    /* const addSgat = (id_Persona) => {
      isLoading.value = true;
      addPermessiSgat(id_Persona).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          alertSuccess("Permessi SGAT assegnati");
          emit("getDocumentiSocieta");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    }; */

    const promuoviMaestroNew = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il tesserato verrà promosso maestro.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Promuovi",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
        }
      });
    };

    const personaUtenza = ref(null);

    const loadingDetail = ref(false);
    const corsi_aggiornamento_snm_list = ref(null);
    const getCorsiAggiornamentoSnm = (id_persona) => {
      loadingDetail.value = true;
      viewCorsiAggiornamentoList(id_persona).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          corsi_aggiornamento_snm_list.value = res.data.results;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "corsi_aggiornamento_snm"
          );
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Persona",
        key: "nominativo_completo",
        sortable: false,
      },
      // {
      //   name: "Cod. SNM",
      //   key: "codice_snm",
      //   sortable: false,
      // },
      // {
      //   name: "Cod. DIR",
      //   key: "codice_dir",
      //   sortable: false,
      // },
      // {
      //   name: "N. Albo",
      //   key: "numero_albo",
      //   sortable: false,
      // },

      {
        name: "Qualifica",
        key: "qualifica",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipo",
        sortable: false,
      },
      {
        name: "Data di emissione",
        key: "data_emissione",
        sortable: false,
      },
      // {
      //   name: "Data acq.",
      //   key: "data_acq",
      //   align: "center",
      // },
      // {
      //   name: "Data dec.",
      //   key: "data_decorrenza",
      //   sortable: false,
      //   align: "center",
      // },

      // {
      //   name: "Attestato generato",
      //   key: "attestato_generato",
      //   sortable: false,
      // },
      {
        name: "Aggiornamento scaricato",
        key: "scaricato",
        sortable: false,
      },
      // {
      //   name: "Inviato",
      //   key: "inviato",
      //   sortable: false,
      // },
      {
        name: "Data/Ora Download",
        key: "data_ora_dw",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST = computed(() =>
      store.getters.getStateFromName(
        "resultsSNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
      )
    );

    const cancellaQualifica = async (id, cognome, nome, data_nascita) => {
      await callFunctionAfterAlert(
        globalApi.SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_DEL,
        { id_persona_attestato: id },
        `Sei sicuro di voler cancellare l'aggiornamento di <br> <b>${cognome} ${nome} ${data_nascita}</b>?`,
        "Qualifica cancellata con successo",
        "Attenzione! Si è verificato un problema nella creazione dell'evento. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          emit("getAttestatiQualificheList");
        }
      });
    };

    const idQualificaAlbo = computed(
      () => store.getters.idQualificaAlboAttestatiQualifiche
    );

    return {
      cancellaQualifica,
      getFormatDate,
      SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST,
      loaded: computed(() =>
        store.getters.getStateFromName(
          "loadedSNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
        )
      ),
      record: computed(() =>
        store.getters.getStateFromName(
          "recordSNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
        )
      ),
      status: computed(() =>
        store.getters.getStateFromName(
          "statusSNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
        )
      ),
      refreshAttestatiQualificheList,
      currentPage: computed(() => store.getters.currentPageAttestatiQualifiche),
      rowsToSkip: computed(() => store.getters.rowsToSkipAttestatiQualifiche),
      fetchRows: computed(() => store.getters.fetchRowsAttestatiQualifiche),
      sortColumn: computed(() => store.getters.sortColumnAttestatiQualifiche),
      sortOrder: computed(() => store.getters.sortOrderAttestatiQualifiche),
      setFetchRowsAttestatiQualifiche,
      setCurrentPageAttestatiQualifiche,
      setSortOrderColumn,
      tableHeader,
      selectedAttestatiQualifiche,
      personaUtenza,
      selectedPersona,
      //addSgat,
      getCorsiAggiornamentoSnm,
      promuoviMaestroNew,
      isLoading,
      isLoadingDetail,
      loadingDetail,
      corsi_aggiornamento_snm_list,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      idTipoAlbo: computed(() => store.getters.idTipoAlboAttestatiQualifiche),
      idCategoriaAlbo: computed(
        () => store.getters.idCategoriaAlboAttestatiQualifiche
      ),
      idQualificaAlbo,
      dataDecDaAttestatiQualifiche: computed(
        () => store.getters.dataDecDaAttestatiQualifiche
      ),
      dataDecAAttestatiQualifiche: computed(
        () => store.getters.dataDecAAttestatiQualifiche
      ),
      searchStatus: computed(
        () => store.getters.searchStatusAttestatiQualifiche
      ),
      id_persona: computed(() => store.getters.id_personaAttestatiQualifiche),
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
