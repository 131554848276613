<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getAttestatiQualificheList')"
        >
          <div class="row pb-4">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap">
                <input
                  name="nome_persona"
                  type="text"
                  placeholder="Persona"
                  class="form-control"
                  autocomplete="off"
                  @input="
                    getTesserati($event.target.value);
                    setNomepersonaAttestatiQualifiche($event.target.value);
                  "
                  :value="nome_persona"
                />
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                  @click="spliceTesserati()"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                :list="tesseratiList"
                @hideList="resetTesserati"
                @selectedTesserato="
                  resetTesserati();
                  nome_persona = $event.name;
                  id_Persona = $event.value;
                  setId_personaAttestatiQualifiche($event.value);
                  setNomepersonaAttestatiQualifiche($event.name);
                "
              ></ListTesserati>
            </div>
            <div class="col-sm-6 filter">
              <SelectInput
                :options="AttestatoQualificaLookup"
                name="tipo_qualifica"
                placeholder="Tipo qualifica"
                :value="id_inviato"
                @changeSelect="setTipoQualifiche($event)"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getAttestatiQualificheList')"
              :disabled="!loaded"
            >
              Cerca
              <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                resetCategorie();
                resetQualfiche();
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { format } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";

export default defineComponent({
  name: "filtriAttestatiQualifiche",
  emits: ["resetFilters", "getAttestatiQualificheList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
    ListTesserati,
  },
  setup(props) {
    const isActive = ref(true);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const albi_tipo = computed(() =>
      store.getters.getStateFromName("albi_tipo")
    );
    const keys = ref("atcq");
    if (!albi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const nomeAttestatiQualifiche = computed(
      () => store.getters.nomeAttestatiQualifiche
    );
    const setNomeAttestatiQualifiche = (e) => {
      const string = e.target.value;
      store.commit("setNomeAttestatiQualifiche", string);
    };

    const cognomeAttestatiQualifiche = computed(
      () => store.getters.cognomeAttestatiQualifiche
    );
    const setCognomeAttestatiQualifiche = (e) => {
      const string = e.target.value;
      store.commit("setCognomeAttestatiQualifiche", string);
    };

    const codiceSnmAttestatiQualifiche = computed(
      () => store.getters.codiceSnmAttestatiQualifiche
    );
    const setCodiceSnmAttestatiQualifiche = (e) => {
      const string = e.target.value;
      store.commit("setCodiceSnmAttestatiQualifiche", string);
    };

    const dataAcqDaAttestatiQualifiche = computed(
      () => store.getters.dataAcqDaAttestatiQualifiche
    );
    const setDataAcqDaAttestatiQualifiche = (event) => {
      store.commit("setDataAcqDaAttestatiQualifiche", event);
    };
    const dataAcqAAttestatiQualifiche = computed(
      () => store.getters.dataAcqAAttestatiQualifiche
    );
    // const setDataAcqAAttestatiQualifiche = (event) => {
    //   store.commit("setDataAcqAAttestatiQualifiche", event);
    // };
    // const dataAcqDaAttestatiQualifiche = computed(
    //   () => store.getters.dataAcqDaAttestatiQualifiche
    // );
    const setdataAcqDaAttestatiQualifiche = (event) => {
      store.commit("setdataAcqDaAttestatiQualifiche", event);
    };
    // const dataAcqAAttestatiQualifiche = computed(
    //   () => store.getters.dataAcqAAttestatiQualifiche
    // );
    const setdataAcqAAttestatiQualifiche = (event) => {
      store.commit("setdataAcqAAttestatiQualifiche", event);
    };
    const dataFineDaAttestatiQualifiche = computed(
      () => store.getters.dataFineDaAttestatiQualifiche
    );
    const setDataFineDaAttestatiQualifiche = (event) => {
      store.commit("setDataFineDaAttestatiQualifiche", event);
    };
    const dataFineAAttestatiQualifiche = computed(
      () => store.getters.dataFineAAttestatiQualifiche
    );
    const setDataFineAAttestatiQualifiche = (event) => {
      store.commit("setDataFineAAttestatiQualifiche", event);
    };
    //societa
    const societaDenominazioneAttestatiQualifiche = computed(
      () => store.getters.societaDenominazioneAttestatiQualifiche
    );
    const idSocietaAttestatiQualifiche = computed(
      () => store.getters.idSocietaAttestatiQualifiche
    );
    const setIdSocietaAttestatiQualifiche = ({ value, name }) => {
      store.commit("setIdSocietaAttestatiQualifiche", value);
      store.commit("setSocietaDenominazioneAttestatiQualifiche", name);
    };
    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    //
    const verificaAttestatiQualifiche = computed(
      () => store.getters.verificaAttestatiQualifiche
    );
    const setVerificaAttestatiQualifiche = (event) => {
      store.commit("setVerificaAttestatiQualifiche", event);
    };
    const statoAttestatiQualifiche = computed(
      () => store.getters.statoAttestatiQualifiche
    );
    const setStatoAttestatiQualifiche = (event) => {
      store.commit("setStatoAttestatiQualifiche", event);
    };
    const statoAggionramentoAttestatiQualifiche = computed(
      () => store.getters.statoAggionramentoAttestatiQualifiche
    );
    const statoUtenzaAttestatiQualifiche = computed(
      () => store.getters.statoUtenzaAttestatiQualifiche
    );
    const setStatoUtenzaAttestatiQualifiche = (event) => {
      store.commit("setStatoUtenzaAttestatiQualifiche", event);
    };

    const categorieAttestatiQualifiche = ref([]);
    const idTipoAttestatiQualifiche = computed(
      () => store.getters.idTipoAttestatiQualifiche
    );
    const resetIdCategoriaAttestatiQualifiche = () => {
      store.commit("setIdCategoriaAttestatiQualifiche", null);
    };
    const setIdTipoAttestatiQualifiche = (event) => {
      if (!albi_tipo.value) return;
      const selecteTipoId = event != "" ? parseInt(event) : null;
      store.commit("setIdTipoAttestatiQualifiche", selecteTipoId);
      categorieAttestatiQualifiche.value.splice(
        0,
        categorieAttestatiQualifiche.value.length
      );
      qualificheAttestatiQualifiche.value.splice(
        0,
        qualificheAttestatiQualifiche.value.length
      );
      albi_tipo.value.forEach((element) => {
        if (element.id === selecteTipoId) {
          element.categorie.forEach((cat) => {
            categorieAttestatiQualifiche.value.push(cat);
          });
        }
      });
    };

    const qualificheAttestatiQualifiche = ref([]);
    const idCategoriaAttestatiQualifiche = computed(
      () => store.getters.idCategoriaAttestatiQualifiche
    );
    const setIdCategoriaAttestatiQualifiche = (event) => {
      const selecteCatId = event != "" ? parseInt(event) : null;
      store.commit("setIdCategoriaAttestatiQualifiche", selecteCatId);
      qualificheAttestatiQualifiche.value.splice(
        0,
        qualificheAttestatiQualifiche.value.length
      );
      categorieAttestatiQualifiche.value.forEach((element) => {
        if (element.id === selecteCatId) {
          element.qualifiche.forEach((qual) => {
            qualificheAttestatiQualifiche.value.push(qual);
          });
        }
      });
    };

    const resetCategorie = () => {
      categorieAttestatiQualifiche.value.splice(
        0,
        categorieAttestatiQualifiche.value.length
      );
    };

    const idQualificaAttestatiQualifiche = computed(
      () => store.getters.idQualificaAttestatiQualifiche
    );
    const setIdQualificaAttestatiQualifiche = (event) => {
      store.commit("setIdQualificaAttestatiQualifiche", event);
    };

    const resetQualfiche = () => {
      qualificheAttestatiQualifiche.value.splice(
        0,
        qualificheAttestatiQualifiche.value.length
      );
    };

    const inputFocus = ref(null);

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          resetCategorie();
          resetQualfiche();
        }
      }
    );

    onMounted(() => {
      if (idTipoAlbo.value) {
        setIdTipoAlbo(idTipoAlbo.value);
      }
      if (idCategoriaAlbo.value) {
        setIdCategoriaAlbo(idCategoriaAlbo.value);
      }
      if (idQualificaAlbo.value) {
        setIdQualificaAlbo(idQualificaAlbo.value);
      }
    });

    setIdTipoAttestatiQualifiche(idTipoAttestatiQualifiche.value);
    setIdCategoriaAttestatiQualifiche(idCategoriaAttestatiQualifiche.value);

    const setStatoAggiornamentoAttestatiQualifiche = (event) => {
      store.commit(
        "setStatoAggiornamentoAttestatiQualifiche",
        event == 2 ? 0 : event
      );
    };
    const statoTesseramentoAttestatiQualifiche = computed(
      () => store.getters.statoTesseramentoAttestatiQualifiche
    );
    const setStatoTesseramentoAttestatiQualifiche = (event) => {
      store.commit(
        "setStatoTesseramentoAttestatiQualifiche",
        event == 2 ? 0 : event
      );
    };

    const stagioni = computed(() => store.getters.stagioni);
    const stagioni_pagamenti_a = ref([]);

    const stagionePagamentoDa = computed(
      () => store.getters.stagionePagamentoDa
    );
    const setStagionePagamentoDa = (event) => {
      store.commit("setStagionePagamentoDa", event);
      store.commit("setStagionePagamentoA", null);
      stagioni_pagamenti_a.value = [];
      stagioni.value.forEach((element) => {
        if (element.id >= stagionePagamentoDa.value) {
          stagioni_pagamenti_a.value.push(element);
        }
      });
    };
    const stagionePagamentoA = computed(() => store.getters.stagionePagamentoA);
    const setStagionePagamentoA = (event) => {
      store.commit("setStagionePagamentoA", event);
    };

    const pagamentiNonRegistrati = computed(
      () => store.getters.pagamentiNonRegistrati
    );
    const setPagamentiNonRegistrati = (event) => {
      console.log(event);
      store.commit("setPagamentiNonRegistrati", event);
    };

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keysComitati = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keysComitati.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idComitatoRegionaleAttestatiQualifiche = computed(
      () => store.getters.idComitatoRegionaleAttestatiQualifiche
    );
    const setComitato = (event) => {
      store.commit("setIdComitatoRegionaleAttestatiQualifiche", event);
    };

    const getTesserati = (string) => {
      store.commit("setNomeTesseratoMovimenti", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const spliceTesserati = () => {
      setId_personaAttestatiQualifiche(null);
      setNomepersonaAttestatiQualifiche("");
      resetTesserati();
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const setId_personaAttestatiQualifiche = (event) => {
      store.commit("setId_personaAttestatiQualifiche", event);
    };
    const setNomepersonaAttestatiQualifiche = (event) => {
      store.commit("setNomepersonaAttestatiQualifiche", event);
    };
    // FILTRI NUOVI PER QUALIFICA
    const categorieAlbo = ref([]);
    const idTipoAlbo = computed(
      () => store.getters.idTipoAlboAttestatiQualifiche
    );
    const resetIdCategoriaAlbo = () => {
      store.commit("setIdCategoriaAlboAttestatiQualifiche", null);
    };
    const setIdTipoAlbo = (event) => {
      console.log(event);
      if (!albi_tipo.value) return;
      const selecteTipoId = event != "" ? parseInt(event) : null;
      store.commit("setIdTipoAlboAttestatiQualifiche", selecteTipoId);
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      albi_tipo.value.forEach((element) => {
        if (element.id === selecteTipoId) {
          element.categorie.forEach((cat) => {
            categorieAlbo.value.push(cat);
          });
        }
      });
    };

    const qualificheAlbo = ref([]);
    const idCategoriaAlbo = computed(
      () => store.getters.idCategoriaAlboAttestatiQualifiche
    );
    const setIdCategoriaAlbo = (event) => {
      const selecteCatId = event != "" ? parseInt(event) : null;
      store.commit("setIdCategoriaAlboAttestatiQualifiche", selecteCatId);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      categorieAlbo.value.forEach((element) => {
        if (element.id === selecteCatId) {
          element.qualifiche.forEach((qual) => {
            qualificheAlbo.value.push(qual);
          });
        }
      });
    };

    const idQualificaAlbo = computed(
      () => store.getters.idQualificaAlboAttestatiQualifiche
    );
    const setIdQualificaAlbo = (event) => {
      store.commit("setIdQualificaAlboAttestatiQualifiche", event);
    };
    const setTipoQualifiche = (event) => {
      store.commit("settipo_qualificaAttestatiQualifiche", event);
    };
    const setIdScaricato = (event) => {
      store.commit("setIdScaricatoAttestatiQualifiche", event);
    };
    const setSearchStatus = () => {
      store.commit("setSearchStatusAttestatiQualifiche");
    };
    return {
      setSearchStatus,
      setTipoQualifiche,
      setIdScaricato,
      id_inviato: computed(() => store.getters.id_inviatoAttestatiQualifiche),
      id_scaricato: computed(() => store.getters.id_inviatoAttestatiQualifiche),
      idTipoAlbo,
      resetIdCategoriaAlbo,
      setIdTipoAlbo,
      idCategoriaAlbo,
      setIdCategoriaAlbo,
      idQualificaAlbo,
      setIdQualificaAlbo,
      categorieAlbo,
      qualificheAlbo,

      tesseratiList: computed(() => store.getters.personeSocieta),
      nome_persona: computed(
        () => store.getters.nomePersonaAttestatiQualifiche
      ),
      setNomepersonaAttestatiQualifiche,
      setId_personaAttestatiQualifiche,
      getTesserati,
      resetTesserati,
      inputFocus,
      categorieAttestatiQualifiche,
      resetCategorie,
      resetQualfiche,
      isActive,
      nomeAttestatiQualifiche,
      setNomeAttestatiQualifiche,
      cognomeAttestatiQualifiche,
      setCognomeAttestatiQualifiche,
      codiceSnmAttestatiQualifiche,
      setCodiceSnmAttestatiQualifiche,
      idTipoAttestatiQualifiche,
      setIdTipoAttestatiQualifiche,
      idCategoriaAttestatiQualifiche,
      setIdCategoriaAttestatiQualifiche,
      idQualificaAttestatiQualifiche,
      setIdQualificaAttestatiQualifiche,
      dataAcqDaAttestatiQualifiche,
      setDataAcqDaAttestatiQualifiche,
      dataAcqAAttestatiQualifiche,
      // setDataAcqAAttestatiQualifiche,
      setdataAcqDaAttestatiQualifiche,
      setdataAcqAAttestatiQualifiche,
      dataFineDaAttestatiQualifiche,
      setDataFineDaAttestatiQualifiche,
      dataFineAAttestatiQualifiche,
      setDataFineAAttestatiQualifiche,
      getSocieta,
      resetSocieta,
      idSocietaAttestatiQualifiche,
      setIdSocietaAttestatiQualifiche,
      societaDenominazioneAttestatiQualifiche,
      societa: computed(() => store.getters.societaNomeCodAff),
      verificaAttestatiQualifiche,
      setVerificaAttestatiQualifiche,
      statoAttestatiQualifiche,
      setStatoAttestatiQualifiche,
      statoUtenzaAttestatiQualifiche,
      setStatoUtenzaAttestatiQualifiche,
      qualificheAttestatiQualifiche,
      albi_tipo,
      AttestatoQualificaLookup: [
        { id: "A", label: "Aggiornamento" },
        { id: "Q", label: "Qualifica" },
      ],
      statoLookup: [
        { id: 1, label: "In regola" },
        { id: 2, label: "Non in regola" },
      ],
      statoUtenzaLookup: [
        { id: 1, label: "Non presente" },
        { id: 2, label: "Bloccata" },
        { id: 3, label: "Attiva" },
      ],
      format,
      loaded: computed(() =>
        store.getters.getStateFromName(
          "loadedSNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
        )
      ),
      resetIdCategoriaAttestatiQualifiche,

      stagioni_pagamenti_a,
      stagioni,
      setStagionePagamentoDa,
      setStagionePagamentoA,
      stagionePagamentoDa,
      stagionePagamentoA,
      setPagamentiNonRegistrati,
      pagamentiNonRegistrati,
      statoAggionramentoAttestatiQualifiche,
      setStatoAggiornamentoAttestatiQualifiche,
      statoTesseramentoAttestatiQualifiche,
      setStatoTesseramentoAttestatiQualifiche,
      setComitato,
      idComitatoRegionaleAttestatiQualifiche,
      comitati,
      id_persona: computed(() => store.getters.id_personaAttestatiQualifiche),
      spliceTesserati,
    };
  },
  computed: {
    puoGenerareQualifiche() {
      console.log(
        "puoGenerareQualifiche",
        this.idTipoAlbo &&
          this.idCategoriaAlbo &&
          this.idQualificaAlbo &&
          this.dataAcqDaAttestatiQualifiche &&
          this.dataAcqAAttestatiQualifiche
      );
      return this.id_persona ? this.id_persona : this.idQualificaAlbo;
    },
  },
});
</script>

<style></style>
